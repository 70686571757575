import { createRouter, createWebHistory } from 'vue-router'

// 公共路由
const Layout = () => import('@/Layout/index.vue');

const router = createRouter({
  history: createWebHistory(),
  routes: [
    // {
    //   path: '/',
    //   name: 'home',
    //   component: () => import('@/views/index.vue')
    // },
    // {
    //   path: '/product/quantity/:id',
    //   name: 'product',
    //   component: () => import('@/views/product/product.vue')
    // },
    // {
    //   path: '/product/productInfo/:id',
    //   name: 'home',
    //   component: () => import('@/views/product/productInfo/productInfo.vue')
    // },
    {
      path: '/',
      name: 'home',
      component: Layout,
      children: [{
        path: '',
        name: 'index',
        meta: {title: 'HOME'},
        component: () => import('@/views/index.vue')
      }]
    },
    {
      path: '/product',
      name: 'product',
      meta: {title: 'PRODUCTS'},
      component: Layout,
      children: [{
        path: 'quantity/:id',
        name: 'product',
        component: () => import('@/views/product/product.vue')
      },{
        path: 'productInfo/:id',
        name: 'productInfo',
        meta: {title: 'PRODUCTS'},
        component: () => import('@/views/product/productInfo/productInfo.vue')
      }]
    }
  ]
})

export default router
